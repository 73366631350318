import { useEffect } from 'react'

import { showToast } from '@biogroup/ui/toaster'

import { Toast } from './toast.server.ts'

export function useToast(toast: Toast | undefined | null) {
  useEffect(() => {
    if (toast) {
      Promise.resolve().then(() => {
        showToast[toast.type](toast.title, {
          id: toast.id,
          description: toast.description,
          action: toast.action,
        })
      })
    }
  }, [toast])
}
